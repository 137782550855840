.container {
    width: fit-content;
    height: fit-content;
}

.background {
    top: 0;
    left: 0;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    height: 100%;
    width: 100%;
    transition: opacity 300ms, visibility 300ms;
    z-index: 10;
}

.show-background {
    visibility: visible;
    opacity: 1;
}

.hide-background {
    opacity: 0;
    visibility: hidden;
}

.menu {
    top: 0;
    right: 0;
    position: fixed;
    width: 0%;
    height: 100%;
    overflow: hidden;
    transition: width 300ms ease;
    z-index: 10;
    overflow: scroll;
    background-color: var(--bg-header);
    display: flex;
    justify-content: center;

    .links {
        margin-top: 80px;

        a {
            text-align: center;
            white-space: nowrap;
            display: block;
            font-size: 24px;
            margin: 12px;
            padding: 4px;
            color: var(--color-header);
            opacity: 0;
            transition: opacity 300ms ease;
        }
    }

    .close-button {
        position: absolute;
        top: 0;
        right: 0;
        margin: 8px;
        transition: opacity 300ms ease;
    }
}

.show-menu {
    width: 80%;

    .links a, .close-button {
        opacity: 1;
    }
}

.hide-menu {
    width: 0%;

    .links a, .close-button {
        opacity: 0;
    }
}
