@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,600;1,300&display=swap");
@import "~react-image-gallery/styles/scss/image-gallery.scss";

:root {
    /* fonts */
    --font: "Poppins", sans-serif;
    --size-title: 1.7rem;
    --size-subtitle: 1.25rem;
    --size-text: 1.2rem;
    --size-title-mobile: 1.5rem;
    --size-subtitle-mobile: 1.25rem;
    --size-text-mobile: 1rem;

    /* colors */
    --color-white: white;
    --color-black: black;
    --color-blue: #0aa;

    /* header */
    --header-top-height: 26px;
    --header-bot-height: 52px;
    --header-height: calc(var(--header-top-height) + var(--header-bot-height));
    --color-header: var(--color-black);
    --bg-header: var(--color-white);

    /* WhatsApp button */
    --whatsapp-button-container-height: 40px;
    --whatsapp-button-height: var(--header-top-height);

    /* main */
    --color-main: var(--color-black);
    --color-secondary: var(--color-blue);
    --bg-main: var(--color-white);

    /* footer */
    --color-footer: var(--color-white);
    --bg-footer: var(--color-black);
}
