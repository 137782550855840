.schedule {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
}

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;

    .table {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;

        h3 {
            text-align: center;
        }

        table th,
        table td {
            padding: 0 36px;
        }
    }
}

.design {
    display: none;
    width: 140px;
}

@media (min-width: 768px) {
    .design {
        display: inline;
    }
}
