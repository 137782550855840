.hero {
    left: 0;
    position: relative;
    text-align: center;
    width: 100vw;
    height: calc(100vh - var(--header-height));
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;

    .description {
        position: relative;
        z-index: 1;
        padding: 20px;
    }
}

.hero::after {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../../public/images/hero-mobile.webp");
    background-size: cover;
    background-position: center;
    opacity: 0.5;
}

.title {
    font-size: 2rem;
    font-weight: 300;
}

.name {
    font-size: 3rem;
    font-weight: 600;
    margin: 0 0 40px 0;
}

.slogan {
    font-size: 1.5rem;
    font-style: italic;
    text-align: center;
}

@media screen and (min-width: 768px) {
    .hero {
        width: 100vw;
    }

    .hero::after {
        background-image: url("../../../public/images/hero.webp");
    }

    .name {
        font-size: 4rem;
    }
}

.prueba {
    left: 0;
    position: absolute;
    height: calc(200vh);
    background-color: red;
}
