.container {
    opacity: 0.05;
    z-index: -2;
}

.teeth {
    position: absolute;
    width: 75px;
    height: 75px;
    background: url("../../../public/images/icons/tooth.png");
    background-size: cover;
    background-repeat: no-repeat;
    
}
