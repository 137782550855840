.about {
    margin: 32px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 32px;
}

.about-container {
    display: flex;
    flex-direction: column-reverse;

    h3 {
        text-align: center;
    }
}

.description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
        margin: 24px 0 12px 0;
        padding: 0;
    }

    p {
        text-align: justify;
    }
}

.image {
    width: 100%;
    height: 200px;
    background-image: url("../../../public/images/about.webp");
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.4);
}

.values {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 36px 0 0 0;
    border: 1px solid black;
    border-radius: 20px;
    padding: 0 20px 20px 20px;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.2);
    background-color: white;

    img {
        width: 100px;
        margin: 8px;
    }

    h4 {
        font-size: larger;
    }

    p {
        text-align: justify;
    }
}

@media (min-width: 768px) {
    .about-container {
        flex-direction: row;

        .description {
            margin-right: 20px;
            width: 50%;
        }

        .image {
            width: 50%;
            height: 320px;
            margin-left: 20px;
        }
    }
}

@media (min-width: 1024px) {
    .about {
        ul {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin-top: 40px;
        }
    }

    .values {
        width: 28%;
    }
}
