.whatsapp-button-container {
    background-color: #25d366;
    top: calc(
        -1 * (var(--whatsapp-button-container-height) -
                    var(--whatsapp-button-height))
    );
    border: none;
    width: 140px;
    height: var(--whatsapp-button-container-height);
    z-index: 2;
    border-radius: 0 0 8px 8px;
    box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.5);
    align-self: flex-end;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;

    .whatsapp-button {
        top: 0;
        position: sticky;
        height: var(--whatsapp-button-height);
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        padding: 0 8px;
        align-items: center;
        cursor: pointer;
        color: black;
    }
}

.whatsapp-icon {
    height: 24px;
}

@media (min-width: 768px) {
    .whatsapp-button-container {
        right: 10%;
    }
}
