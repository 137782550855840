.about {
    padding-top: 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    text-align: center;
    margin: 40px 0 12px 0;
}

.cover {
    width: 100%;
    margin-bottom: 12px;
}

.description {
    text-align: justify;
    margin-bottom: 12px;
}

.doctor {
    width: 80%;
    margin: 20px 0 32px 0;
}

h3 {
    text-align: center;
    margin-bottom: 12px;
}

p {
    text-align: justify;
    margin-bottom: 12px;
}

@media screen and (min-width: 768px) {
    .cover {
        width: 50%;
        margin: 20px 0;
    }

    .doctor {
        width: 40%;
    }

    .values {
        display: flex;
        justify-content: center;
        width: 100%;

        li {
            width: 40%;
            margin: 0 20px 40px 20px;
        }
    }
}
