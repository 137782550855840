.footer {
    position: relative;
    width: 100%;
    color: var(--color-footer);
    background-color: var(--bg-footer);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 60px 15px 60px;
    z-index: 1;
    font-size: 1rem;
}

.row1 {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    padding: 0;

    .col1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-bottom: 20px;

        ul {
            // width: 100%;
            li {
                text-align: center;

                a {
                    color: white;
                }
            }
        }
    }

    .col2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-bottom: 20px;

        p {
            text-align: center;
        }
    }

    .col3 {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-bottom: 20px;

        li {
            text-align: center;
        }
    }
}

.row2 {
    margin-top: 40px;
    width: 100%;

    p {
        text-align: center;
    }
}

@media (min-width: 768px) {
    .row1 {
        .col1,
        .col2,
        .col3 {
            width: 50%;
        }
    }

    .row1 {
        .col3 {
            li {
                text-align: justify;
            }
        }
    }
}

@media (min-width: 1024px) {
    .row1 {
        .col1,
        .col2,
        .col3 {
            width: 33%;
        }
    }
}
