.contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 28px;
}

.contact-icon {
    img {
        height: 120px;
        border: 1px solid rgba(0, 0, 0, 1);
        border-radius: 50%;
        margin-top: 8px;
        padding: 2px;
    }
}

.title {
    text-align: center;
    margin: 40px 0 0 0;
    font-size: 24px;
}

.subtitle {
    text-align: center;
    margin: 40px 0 6px 0;
    font-size: 18px;
}

.facebook {
    display: flex;
    height: 24px;

    img {
        height: 100%;
        margin-right: 8px;
    }
}

.mail {
    display: flex;
    height: 24px;

    img {
        height: 100%;
        margin-right: 8px;
    }
}

.numbers li a {
    color: var(--color-blue);
}

.numbers li a:hover {
    text-decoration: underline;
}

.welcome {
    text-align: justify;
}

.address {
    text-align: center;
    margin: 16px 0 4px 0;
    background-color: #02427a;
    margin: 20px;
    padding: 8px 12px;
    color: white;
    border-radius: 8px;
}

iframe {
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 280px;
    padding: 0;
    margin: 0;
}

@media screen and (min-width: 768px) {
    .contact {
        padding-top: 40px;
    }

    iframe {
        height: 400px;
    }
}
