.find-us {
    display: flex;
    flex-direction: column;
    align-items: center;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .address {
            display: flex;
            margin-bottom: 1rem;
            text-align: center;
        }

        .welcome {
            text-align: center;
        }

        .schedule {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 32px 0;
            width: 100%;

            h3 {
                text-align: center;
            }

            table th,
            table td {
                padding: 0 36px;
            }
        }

        .map {
            width: 100%;

            h3 {
                text-align: center;
            }

            a {
                display: block;
            }

            iframe {
                border: 1px solid #acc;
                width: 100%;
                min-height: 400px;
                border-radius: 12px;
            }
        }
    }
}
