body {
    margin: 0;
    font-family: var(--font);
    font-size: var(--size-text-mobile);
}

header,
main > *,
footer > * {
    padding: 0 5%;
}

main {
    flex-grow: 1;
    color: var(--color-primary);
    overflow-x: hidden;
    margin-top: calc(-1 * var(--whatsapp-button-container-height));
}

.section-title {
    border-bottom: 1px solid var(--color-secondary);
    text-align: center;
    margin: 32px 0;
    padding: 0;
    font-size: var(--size-title-mobile);
}

.more-button {
    position: relative;
    color: var(--color-secondary);
    margin: 12px 0;
    padding: 8px 12px;
    border-radius: 6px;
    border: 1px solid var(--color-secondary);
    background-color: white;
    box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.2);
    transition: all 140ms ease-in-out;
    font-size: 1rem;
    cursor: pointer;
}

.more-button:hover {
    transform: scale(1.04);
    box-shadow: 0 0 2px 0 var(--color-secondary);
    text-shadow: 0 0 1px var(--color-secondary);
}

@media (min-width: 768px) {
    header,
    main > *,
    footer > * {
        padding: 0 10%;
    }

    body {
        font-size: var(--size-text);
    }

    .section-title {
        font-size: var(--size-title);
    }
}
