.services {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 32px 0 0 0;

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        list-style: none;
        padding: 0;
    }
}

@media (min-width: 1024px) {
    .services {
        ul {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }
}
