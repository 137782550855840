.services {
    padding-top: 28px;
}

.title {
    text-align: center;
    margin: 40px 0 0 0;
    font-size: 24px;
}

.services-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.service {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    margin: 8px;
    padding: 8px;
    border-radius: 4px;
    text-align: center;
    background-color: white;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.2);

    img {
        width: 140px;
        height: 140px;
        border-radius: 8px;
        margin-bottom: 12px;
    }

    h3 {
        margin: 8px 0;
    }
}

@media screen and (min-width: 768px) {
    .service {
        margin: 12px 24px;
    }
}
