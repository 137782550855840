.service {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #f4f4f4;
    border-radius: 10px;
    padding: 20px;
    margin: 8px 0;
    width: 100%;
    transition: all 0.3s ease;
}

.service:hover {
    transform: scale(1.02);
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
}

.serviceImage {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
}

.serviceInfo{
    width: 100%;
}

.serviceTitle {
    font-size: 1.2em;
    color: #333;
    margin: 0;
}

@media (min-width: 768px) {
    .service {
        flex-direction: row;
        justify-content: start;
        padding: 30px;
    }

    .serviceImage {
        width: 120px;
        height: 120px;
        margin-right: 20px;
        margin-bottom: 0;
    }

    .serviceTitle {
        font-size: 1.4em;
    }
}

@media (min-width: 1024px) {
    .service {
        max-width: 45%;
        margin: 20px;
    }
}
