.header-container {
    position: sticky;
    top: 0;
    width: 100%;
    height: var(--header-height);
    z-index: 2;
    background-color: var(--bg-header);
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
    padding: 0;
}

.top-header {
    height: var(--header-top-height);
    padding: 0 5%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    a {
        height: 100%;
        width: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: black;
        background-color: #8dd;
        border-radius: 0 0 8px 8px;
        box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.5);
        padding: 0 8px;
        font-size: 1rem;

        img {
            height: 100%;
            margin-right: 8px;
        }
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    color: var(--color-header);
    width: 100%;
    height: var(--header-bot-height);
    padding: 0 5%;
}

.logo {
    display: flex;
    align-items: center;
    padding: 8px 0;
    height: var(--header-bot-height);
}

.logo img {
    max-height: 100%;
    max-width: 290px;
}

.navigation {
    display: none;
}

.hamburguer-icon {
    display: flex;
}

@media (min-width: 768px) {
    .header,
    .top-header {
        padding: 0 10%;
    }

    .header {
        font-size: 1.1rem;
    }

    .hamburguer-icon {
        display: none;
    }

    .navigation {
        display: initial;
        height: 100%;
        .links {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;

            a {
                margin: 0 4px;
                padding: 4px 4px;
                color: var(--color-header);

                &:hover {
                    border-bottom: 2px solid var(--color-blue);
                }
            }
        }
    }
}

@media (min-width: 1024px) {
    .header {
        font-size: 1.2rem;
    }
}
