.doctor {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #eff;
    text-align: justify;

    .name {
        font-size: 1.5rem;
        font-weight: 300;
        text-align: center;
    }

    .overview {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            width: 80%;
            border-radius: 12px;
        }
    }

    .achievements {
        margin: 24px 0 24px 24px;

        li {
            list-style: inherit;
            margin: 8px 0;
        }
    }
}

@media (min-width: 768px) {
    .container {
        padding-bottom: 32px;

        .overview {
            flex-direction: row;
            justify-content: center;
            height: 300px;

            img {
                width: initial;
                height: 100%;
                margin: 0 40px 0 0;
            }
        }
    }
}
